<template>
  <form @input="$emit('input', letterWords)">    
    <div v-for="(item, i) in letterWords" :key="i">
      <div class="ml-40">
        <div v-for="(inner, j) in item" :key="j">
          <input type="text" v-model="inner.word" placeholder="Word" />
          <input type="text" v-model="inner.image" placeholder="Image link" />
        </div>
        <button type="button" @click="addItem(item)">
          + Insert
        </button>
        <button class="ml-10" type="button" @click="copyItem(item)">
          ][ Copy
        </button>
      </div>
    </div>

    <button
      type="button"
      @click="
        letterWords.push([
          {
            word: '',
            image: '',
          },
        ])
      "
    >
      + Add Item
    </button>
  </form>
</template>

<script>
// "letterWords": [
//   [
//     {
//       "word": "shield",
//       "image": "type-4-a/shield.svg"
//     },
//     {
//       "word": "sword",
//       "image": "type-4-a/sword.svg"
//     }
//   ],
// ]
export default {
  name: 'Type4a',
  data() {
    return {
      letterWords: [
        [
          {
            word: '',
            image: '',
          },
        ],
      ],
      timer: 40
    };
  },
  methods: {
    addItem(data) {
      data.push({
        word: '',
        image: '',
      });
    },
    copyItem(item) {
      let lastItem = item[item.length - 1]
      let itemCopy = Object.assign({}, lastItem)
      item.push(itemCopy)
    },
  },
};
</script>
